$(function(){
    let slFaqBlock = document.querySelector('.faq .faq-slider');
    if (slFaqBlock) {
        console.log('init slider faq');
        let slFaq = new Swiper(slFaqBlock, {
            slidesPerView: 'auto',
            navigation: {
                nextEl: document.querySelector('.faq .sl-nav__btn--next'),
                prevEl: document.querySelector('.faq .sl-nav__btn--prev'),
            },
            pagination: {
                el: document.querySelector('.faq .swiper-pagination'),
                type: 'fraction',
            },
        });
    }

    let slFaqModalBlock = document.querySelector('.modal-faq .modal-faq-slider');
    if (slFaqModalBlock) {
        console.log('init slider modal-faq');
        let slFaqModal = new Swiper(slFaqModalBlock, {
            spaceBetween: 30,
            navigation: {
                nextEl: document.querySelector('.modal-faq .sl-nav__btn--next'),
                prevEl: document.querySelector('.modal-faq .sl-nav__btn--prev'),
            },
            effect: 'fade',
        });
        $('.faq-slider__row').on('click', function(){
            let index = $(this).data('slide')
            console.log(index);
            slFaqModal.slideTo(index)
        });
    }




});