$(function(){
    //PLAY HERO VIDEO
    setTimeout(() => {
        let heroVideoSrc = $('.hero__video-video').data('source');
        $('.hero__video-video').attr('src', heroVideoSrc);
        let heroVideo = document.querySelector('.hero__video-video');
        heroVideo.play();
    }, 2000);

    //CODE FOR FULL PAGE***************************
    let fpInited = false;
    function initFullpage(){
        fpInited = true;
        console.log('initFullpage func fired');
        $('#fullpage').fullpage({
            // scrollOverflow: true,
            scrollingSpeed: 800,
            // navigation: true,
            // navigationPosition: 'left',
            responsiveHeight: 450,
            anchors: ['heroSection', 'firstPage', 'secondPage', 'cards','faqSection', 'formSlide'],
            menu: '#menu',
            slidesNavigation: true,
            onLeave: function (origin, index, direction) {
                let item = $(".section").eq(index-1);
                if ( item.attr('data-theme') ) {
                    $('body').addClass('light-section');
                    // PLAY FIRST PAGE VIDEO
                    if(item.hasClass('first-page')){
                        let video = item.find('video')
                        let fpVideoSrc = video.data('source');
                        video.attr('src', fpVideoSrc);
                        // video[0].play();
                        // console.log('second section video play');
                    }
                    console.log(index);
                    if (index == 6){
                        $('.navigation').addClass('last-section')
                    }else{
                        $('.navigation').removeClass('last-section')
                    }

                }else{
                    $('body').removeClass('light-section');
                }
                let heroVideo = document.querySelector('.hero__video-video');
                heroVideo.play();
                console.log('hero video play comand');
            },
        });
    }
    if ($('#fullpage').length && window.innerWidth >= 769) {
        initFullpage()
    }
    $(window).on('resize', function(){

        if (window.innerWidth <= 769 && fpInited){
            $.fn.fullpage.destroy('all');
            fpInited = false;
        }else if (window.innerWidth >= 769){
            if(!fpInited){
                initFullpage();
            }
        }

    });
    //CODE FOR FULL PAGE***************************

    $('.second-page__banner-btn').on('click', function(){
        console.log('test 123');
    })

    $(window).on('scroll', function(){
        let fp_top = document.querySelector('.first-page').getBoundingClientRect().top;
        if(fp_top < 50){
            $('body').addClass('light-section');
            console.log($('.first-page__content-right video').attr('src'));
            if (!$('.first-page__content-right video').attr('src')){
                let video_src = $('.first-page__content-right video').data('source')
                $('.first-page__content-right video').attr('src', video_src)
            }

        }else{
            $('body').removeClass('light-section');
        }
        if ($(window).scrollTop() > 10 && window.innerWidth <= 769){
            $('.header').addClass('is-scrolled');
        }else{
            $('.header').removeClass('is-scrolled');
        }
    })

    //BURGER 
    $('.burger').on('click', function(){
        if($(this).hasClass('is-active')){
            $(this).removeClass('is-active');
            $('.nav').removeClass('is-active');
            $('.header').removeClass('menu-open');
            noScroll.enableScroll();
        }else{
            $(this).addClass('is-active');
            $('.nav').addClass('is-active');
            if($(window).scrollTop() > 10){
                $('.header').addClass('menu-open');
            }
            noScroll.disableScroll();
        }

    })

    $('a[data-nav]').on('click', function(e){
        
        if (window.innerWidth <= 769){
            noScroll.enableScroll();
            $('.nav.is-active').removeClass('is-active');
            $('.burger.is-active').removeClass('is-active');
            let _href = $(this).data('nav');
            console.log(_href);
            setTimeout(() => {
                $("html, body").animate({ scrollTop: $(_href).offset().top -60+ "px" }, 500);
                return false;
            }, 10);
        }

    })
});