document.addEventListener("DOMContentLoaded", function() {

    $('.input-group input, .input-group textarea').on('focus', function () {
        $(this).closest('.input-group').addClass('input-group--focus');
    });
    $('.input-group input, .input-group textarea').on('blur', function () {
        $(this).closest('.input-group').removeClass('input-group--focus');
        if ( $(this).val().length ) {
            $(this).closest('.input-group').addClass('input-group--notempty');
        } else {
            $(this).closest('.input-group').removeClass('input-group--notempty');
        }
    });
    $('input[type=tel]').inputmask({
        showMaskOnHover: false,
        showMaskOnFocus: true
    });
    // data-inputmask='"mask": "+7(999) 999-99-99"' data-num-min='11' data-msg-checkMin='Введите весь номер' placeholder='+7(___) ___-__-__'

    //DROP AREA 
    var fileInput = $('.file-input');
    var dropArea = $('.file-drop-area');

    fileInput.on('dragenter focus click', function() {
        dropArea.addClass('is-active');
    });

    fileInput.on('dragleave blur drop', function() {
        dropArea.removeClass('is-active');
    });

    fileInput.on('change', function() {
        var filesCount = $(this)[0].files.length;

        if (filesCount !== 0) {
            let fileName = $(this).val().split('\\').pop();
            $('.file-upload-val__text').text(fileName);
            dropArea.addClass('hide');
            $('.file-upload-val').addClass('is-active');
        } 
    });

    $('.file-upload-val__del').click(function() {
        dropArea.removeClass('hide');
        $('.file-upload-val__text').text('');
        $('.file-upload-val__text').removeAttr('style');
        $('.file-upload-val').removeClass('is-active');
        fileInput.val('');
    });

});