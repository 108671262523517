$(function(){
    function initCardsGrid (){
        //COUNT FORR LEFT CARD BOX
        console.log('initCardsGrid function fired');
        let cardBoxLeftItem = $('.cards-box--left').find('.cards-box__item');
        let cardBoxLeftItemLength = cardBoxLeftItem.length
        if (cardBoxLeftItemLength > 8){
            cardBoxLeftItem.closest('.cards-box').addClass('cards-box--16')
        }else if(cardBoxLeftItemLength > 4){
            cardBoxLeftItem.closest('.cards-box').addClass('cards-box--8')
        }else if (cardBoxLeftItemLength <= 4){
            cardBoxLeftItem.closest('.cards-box').addClass('cards-box--4')
        }

        //COUNT FORR RIGHT CARD BOX
        let cardBoxRightItem = $('.cards-box--right').find('.cards-box__item');
        let cardBoxRightItemLength = cardBoxRightItem.length
        if (cardBoxRightItemLength > 16){
            cardBoxRightItem.closest('.cards-box').addClass('cards-box--r16')
        }else if(cardBoxRightItemLength > 8){
            cardBoxRightItem.closest('.cards-box').addClass('cards-box--r8')
        }else if (cardBoxRightItemLength <= 4){
            cardBoxRightItem.closest('.cards-box').addClass('cards-box--r4')
        }
    }
    initCardsGrid ();

    function removeCardBoxClasses (){
        if (window.innerWidth <= 1600){
            let cardBox = $('.cards-box');
            let classArr = [
                'cards-box--16',
                'cards-box--8',
                'cards-box--4',
                'cards-box--r16',
                'cards-box--r8',
                'cards-box--r4',
            ]
            $.each(classArr, function(index,value){
                if(cardBox.hasClass(value)){
                    cardBox.removeClass(value)
                }
            })
        }else{
            initCardsGrid ();
        }
    }

    window.addEventListener('resize', debounce(removeCardBoxClasses, 160));
})

